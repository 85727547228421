(function ($) {

    $(window).on('load', function () {
        setTimeout(function () {
            if ($('body').hasClass('elementor-editor-active')) {
                $('header').removeClass('fixed-top')
            }
        }, 1000)
    })

    // Sticky Header
    $(window).on("scroll load", function () {
        if ($(window).scrollTop() >= 50) {
            $('header').addClass('scrolled');
        } else {
            $('header').removeClass('scrolled');
        }
    });

    // Menu Toggle
    $('.js-menu-toggle').click(function () {
        $('.c-header').toggleClass('is-open')
        $('html, body').toggleClass('scroll-lock')
    })

})(jQuery);